import AnaliticsIcon from 'icons/analitics.svg';
import ArrowRightIcon from 'icons/jp-header-arrow-right.svg';
import {useDevicevars} from 'lib/devicevars';
import {reverseUrl} from 'lib/router';
import {JOOM_PRO_ANALYTICS_URL} from 'lib/router/constants';
import {useRouter} from 'next/router';
import {defineMessages, useIntl} from 'react-intl';

const messages = defineMessages({
  about: {
    defaultMessage: 'About us',
    description: 'Header. Navigation. Page name',
  },
  analytics: {
    defaultMessage: 'Analytics',
    description: 'Header. Navigation. Page name',
  },
  blog: {
    defaultMessage: 'Blog',
    description: 'Header. Navigation. Page name',
  },
});

export type HeaderNavItem = {
  href: string;
  icon: React.ReactElement;
  message: string;
};

export const useNavLinks = (): HeaderNavItem[] => {
  const intl = useIntl();
  const {landingConfig, landingBr112024} = useDevicevars();
  const router = useRouter();
  const withAbout = landingConfig?.main?.show && landingBr112024 && router.pathname !== '/about';

  if (withAbout) {
    return [
      {
        href: JOOM_PRO_ANALYTICS_URL.root,
        icon: <AnaliticsIcon />,
        message: intl.formatMessage(messages.analytics),
      },
      {
        href: reverseUrl.about(),
        icon: <ArrowRightIcon />,
        message: intl.formatMessage(messages.about),
      },
      {
        href: reverseUrl.blog(),
        icon: <ArrowRightIcon />,
        message: intl.formatMessage(messages.blog),
      },
    ];
  }

  return [
    {
      href: JOOM_PRO_ANALYTICS_URL.root,
      icon: <AnaliticsIcon />,
      message: intl.formatMessage(messages.analytics),
    },
    {
      href: reverseUrl.blog(),
      icon: <ArrowRightIcon />,
      message: intl.formatMessage(messages.blog),
    },
  ];
};
