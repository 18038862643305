import cn from 'classnames';
import {Avatar} from 'components/Avatar';
import AttentionIcon from 'icons/attention-fill.svg';
import ProfileIcon from 'icons/profile.svg';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {useDevicevars} from 'lib/devicevars';
import {TestIdProp} from 'lib/testing/types';
import {User} from 'lib/user/types';
import React, {useCallback, useRef, useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Menu, MenuItem} from 'uikit/Menu';
import {Button, ButtonDirection, ButtonTestId} from '../Button';
import styles from './index.module.scss';

const messages = defineMessages({
  completeRegistration: {
    defaultMessage: 'Complete registration',
    description: 'Page header: Complete registration button',
  },
  orders: {
    defaultMessage: 'My orders',
    description: 'Page header: Orders menu item',
  },
  profile: {
    defaultMessage: 'Profile',
    description: 'Page header: Profile button',
  },
  signIn: {
    defaultMessage: 'Sign in',
    description: 'Page header: Login button',
  },
  signOut: {
    defaultMessage: 'Sign out',
    description: 'Page header: Exit menu item',
  },
});

export type ProfileTestId = {
  button: ButtonTestId;
  menu: {
    signOutButton: unknown;
  };
};

type Props = TestIdProp<ProfileTestId> & {
  direction?: ButtonDirection;
  onSignIn: () => void;
  onSignOut: () => void;
  user: User;
};

export function Profile({onSignIn, onSignOut, testId, user, direction}: Props): React.ReactElement {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const rootRef = useRef<HTMLDivElement>(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const {isContinueRegistrationButtonVisible} = useDevicevars();

  const handleClickCompleteRegistration = useCallback(() => {
    onSignIn();

    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        source: appEnv.currentPageName,
      },
      type: 'completeRegistrationClick',
    });
  }, [onSignIn, analytics.sendEvent]);

  const handleClick = useCallback(() => {
    if (user.anonymous) {
      onSignIn();
    } else {
      setMenuVisible(true);
    }

    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        source: appEnv.currentPageName,
      },
      type: 'userProfileClick',
    });
  }, [onSignIn, user.anonymous, appEnv, analytics.sendEvent, setMenuVisible]);

  const handleMenuClose = useCallback(() => setMenuVisible(false), []);
  const handleSignOut = useCallback(() => {
    onSignOut();
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        source: appEnv.currentPageName,
      },
      type: 'signOutClick',
    });
  }, [onSignOut, analytics.sendEvent]);

  const visibleCompleteRegistrationButton = isContinueRegistrationButtonVisible && !user.isSelfServiceDataFilled;

  let icon;
  let label;

  if (user.anonymous) {
    icon = <ProfileIcon />;
    label = messages.signIn;
  } else {
    icon = user.avatar ? (
      <div className={styles.avatar}>
        <Avatar image={user.avatar} sizes={24} />
      </div>
    ) : (
      <ProfileIcon />
    );
    label = messages.profile;
  }

  return (
    <div
      className={cn(styles.profile, {
        [styles.unfilled]: visibleCompleteRegistrationButton,
      })}
      data-test-id={testId}
      ref={rootRef}
    >
      <Button direction={direction} icon={icon} label={label} onClick={handleClick} />
      {user && menuVisible && (
        <Menu
          hideArrow={isContinueRegistrationButtonVisible}
          isRounded={isContinueRegistrationButtonVisible}
          onClose={handleMenuClose}
          targetRef={rootRef}
          testId={testId?.menu}
        >
          {visibleCompleteRegistrationButton && (
            <MenuItem onClick={handleClickCompleteRegistration} testId={testId?.menu.signOutButton}>
              <div className={styles.completeRegistrationContent}>
                <AttentionIcon className={styles.completeRegistrationIcon} width={16} />
                <FormattedMessage {...messages.completeRegistration} />
              </div>
            </MenuItem>
          )}
          <MenuItem onClick={handleSignOut} testId={testId?.menu.signOutButton}>
            <FormattedMessage {...messages.signOut} />
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}
