import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

export type ContentTestId = unknown;
export type Props = TestIdProp<ContentTestId> & {
  children: React.ReactNode;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

export function Content({leftIcon, rightIcon, children, testId}: Props): React.ReactElement {
  return (
    <div className={styles.container} data-test-id={testId}>
      {leftIcon && <span className={styles.icon}>{leftIcon}</span>}
      <span className={styles.content}>{children}</span>
      {rightIcon && <span className={styles.icon}>{rightIcon}</span>}
    </div>
  );
}
