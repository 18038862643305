import {ButtonColor} from 'uikit/Button';
import themes from './theme.module.scss';
import {HeaderAction, HeaderTheme} from './types';

export const DEFAULT_ACTIONS: HeaderAction[] = ['cart', 'orders', 'profile'];
export const CLASS_BY_THEME: Record<HeaderTheme, string> = {
  dark: themes.dark,
  light: themes.light,
};

export const BUTTON_OUTLINE_COLOR_BY_THEME: Record<HeaderTheme, ButtonColor> = {
  dark: 'outline-white',
  light: 'outline-black',
};
