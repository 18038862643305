import {Category} from 'lib/category/types';
import React, {useMemo} from 'react';
import {Content} from './Content';
import {Current} from './Current';
import {Icon} from './Icon';
import {Item} from './Item';
import {Parent} from './Parent';
import {BuildUrl} from './types';

type Props = {
  buildUrl: BuildUrl;
  categories?: Category[];
  currentCategory?: Category;
};

function CategoriesList({buildUrl, categories, currentCategory}: Props): React.ReactElement {
  const withIcon = useMemo(
    () => !currentCategory && categories?.every((item) => !item.parentId),
    [categories, currentCategory],
  );

  return (
    <div>
      {currentCategory && <Current buildUrl={buildUrl} category={currentCategory} />}
      {categories?.map((item) => <Item buildUrl={buildUrl} category={item} key={item.id} withIcon={withIcon} />)}
    </div>
  );
}

/* eslint-disable @typescript-eslint/naming-convention */
export const Categories = Object.assign(CategoriesList, {
  Content,
  Current,
  Icon,
  Item,
  Parent,
});
/* eslint-enable @typescript-eslint/naming-convention */
