import {useBooleanState} from '@joomcode/deprecated-utils/react/useBooleanState';
import cnb from 'classnames/bind';
import {ButtonDisclosure} from 'components/ButtonDisclosure';
import {Categories} from 'components/Categories';
import {Link} from 'components/Link';
import {Category} from 'lib/category/types';
import {reverseUrl} from 'lib/router';
import React from 'react';
import {Color} from '../types';
import styles from './index.module.scss';

const cn = cnb.bind(styles);

type Props = {
  category: Category;
  color?: Color;
};

const MIN_VISIBLE_ITEMS_COUNT = 3;
const DEFAULT_CATEGORY_CHILDREN: Category[] = [];

export function CategoryBlock({category, color = 'black'}: Props): React.ReactElement {
  const {value: expanded, toggle: toggleExpanded} = useBooleanState(false);

  const {children = DEFAULT_CATEGORY_CHILDREN} = category;
  const withToggleButton = children.length > MIN_VISIBLE_ITEMS_COUNT;
  const withChildren = children.length > 0;

  return (
    <div className={cn('container', `color-${color}`)}>
      <Link className={styles.link} href={reverseUrl.search({categoryId: category.id})}>
        <span className={styles.name}>{category.name}</span>
      </Link>
      {withChildren && (
        <div className={styles.content}>
          {children.slice(0, expanded ? undefined : MIN_VISIBLE_ITEMS_COUNT).map((child) => (
            <Link className={styles.link} href={reverseUrl.search({categoryId: child.id})} key={child.id}>
              <Categories.Content>{child.name}</Categories.Content>
            </Link>
          ))}
          {withToggleButton && <ButtonDisclosure expanded={expanded} onClick={toggleExpanded} />}
        </div>
      )}
    </div>
  );
}
