import {SearchSuggestionText} from 'lib/search/suggest';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {HighlightedText, HighlightedTextTestId} from '../HighlightedText';
import {ItemProps} from '../types';

export type TextItemTestId = HighlightedTextTestId;

type Props = TestIdProp<TextItemTestId> & ItemProps<SearchSuggestionText>;

export function TextItem({item, query, testId}: Props): React.ReactElement {
  return (
    <HighlightedText testId={testId} value={query}>
      {item.searchParams.phrase || ''}
    </HighlightedText>
  );
}
