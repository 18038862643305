import cn from 'classnames';
import React from 'react';
import styles from './index.module.scss';

export type Props = {
  expanded: boolean;
  onClick?(): void;
};

export function HeaderBurgerMenu({onClick, expanded}: Props): React.ReactElement {
  return <button className={cn(styles.button, {[styles.expanded]: expanded})} onClick={onClick} type='button' />;
}
