import {Link} from 'components/Link';
import {Category} from 'lib/category/types';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import {Content, Props as ContentProps, ContentTestId} from '../Content';
import {BuildUrl} from '../types';
import styles from './index.module.scss';

export type TemplateTestId = {
  content: ContentTestId;
};

export type Props = TestIdProp<TemplateTestId> &
  ContentProps & {
    buildUrl?: BuildUrl;
    category: Category;
    onClick?(category: Category): void;
  };

export function Template({
  leftIcon,
  rightIcon,
  buildUrl,
  category,
  children,
  onClick,
  testId,
}: Props): React.ReactElement {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(category);
    }
  }, [category, onClick]);

  const content = (
    <Content leftIcon={leftIcon} rightIcon={rightIcon}>
      {children}
    </Content>
  );

  if (buildUrl) {
    return (
      <Link className={styles.link} href={buildUrl({categoryId: category.id})} onClick={handleClick} testId={testId}>
        {content}
      </Link>
    );
  }

  return (
    <button className={styles.button} data-test-id={testId} onClick={handleClick} type='button'>
      {content}
    </button>
  );
}
