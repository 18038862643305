import {DataState} from '@joomcode/deprecated-utils/dataState';
import {isNullish} from '@joomcode/deprecated-utils/function';
import {Categories} from 'components/Categories';
import {IconSize} from 'components/Categories/types';
import {NavigationHeader} from 'components/NavigationHeader';
import {CategoryPositionOnPage} from 'events/types/CategoryPositionOnPage';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {Category} from 'lib/category/types';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {DotsLoading} from 'uikit/DotsLoading';
import styles from './index.module.scss';
import {messages} from './messages';

type Props = {
  category: Category;
  categoryState: DataState;
  iconSize?: IconSize;
  isRoot: boolean;
  onBack?(): void;
  onClickCategory(category: Category): void;
  onClose?(): void;
  position: CategoryPositionOnPage;
  withCategory?: boolean;
  withHeader?: boolean;
};

export function SearchCategoryTree({
  withCategory = false,
  withHeader = false,
  iconSize,
  isRoot,
  position,
  category,
  categoryState,
  onClickCategory,
  onBack,
  onClose,
}: Props) {
  const intl = useIntl();
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const isLoading = categoryState === DataState.LOADING;

  const handleCategoryClick = useCallback(
    (item: Category) => {
      onClickCategory(item);
      analytics.sendEvent({
        payload: {
          categoryId: item.id,
          pageUrl: window.location.href,
          position,
          source: appEnv.currentPageName,
        },
        type: 'categoryClick',
      });
    },
    [onClickCategory, analytics, position],
  );

  const handleMainCategoryClick = useCallback(() => {
    handleCategoryClick(category);
  }, [category, handleCategoryClick]);

  return (
    <div className={styles.content}>
      {withHeader && <NavigationHeader onBack={onBack} onClose={onClose} title={category.name} withBack={!isRoot} />}
      <div className={styles.items}>
        {withCategory && (
          <button className={styles.button} onClick={handleMainCategoryClick} type='button'>
            {intl.formatMessage(isRoot ? messages.root : messages.category)}
          </button>
        )}
        {category?.children?.map((item) => (
          <Categories.Item
            category={item}
            iconSize={iconSize}
            key={item.id}
            onClick={handleCategoryClick}
            withIcon={isNullish(item.parentId)}
          />
        ))}
      </div>
      {isLoading && <DotsLoading />}
    </div>
  );
}
