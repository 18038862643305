import ProfileIcon from 'icons/profile.svg';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import React, {useCallback} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Button, ButtonColor, ButtonShape} from 'uikit/Button';

const messages = defineMessages({
  signIn: {
    defaultMessage: 'Sign In',
    description: '[button] Page header. Navigation button label',
  },
});

type Props = {
  color?: ButtonColor;
  onClick(): void;
  shape?: ButtonShape;
  withText?: boolean;
};

export function SignInButton({
  onClick,
  withText,
  color = 'outline-black',
  shape = 'circle',
}: Props): React.ReactElement {
  const intl = useIntl();
  const appEnv = useAppEnv();
  const analytcis = useAnalytics();
  const handleClick = useCallback(() => {
    onClick();
    analytcis.sendEvent({
      payload: {
        pageUrl: window.location.href,
        source: appEnv.currentPageName,
      },
      type: 'signInClick',
    });
    analytcis.dataLayer({event: 'clickSignInButton'});
  }, [onClick, appEnv.currentPageName, analytcis.sendEvent, analytcis.dataLayer]);

  return (
    <Button color={color} iconLeft={<ProfileIcon height={24} width={24} />} onClick={handleClick} shape={shape}>
      {withText && intl.formatMessage(messages.signIn)}
    </Button>
  );
}
