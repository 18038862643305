import {assertNever} from '@joomcode/deprecated-utils/types';
import classNamesBind from 'classnames/bind';
import {SearchSuggestion, SearchSuggestionKind} from 'lib/search/suggest';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import styles from './index.module.scss';
import {TextItem, TextItemTestId} from './TextItem';

const cn = classNamesBind.bind(styles);

export type ItemTestId = {
  text: TextItemTestId;
};

type Props = TestIdProp<ItemTestId> & {
  highlight?: boolean;
  item: SearchSuggestion;
  onSelect(phrase: SearchSuggestion): void;
  query: string;
};

export function Item({highlight, item, query, testId, onSelect}: Props): React.ReactElement {
  const handleClick = useCallback(() => onSelect(item), [item]);
  const {kind} = item;

  let children: React.ReactNode = null;
  switch (kind) {
    case SearchSuggestionKind.STORE:
      children = item.id;
      break;
    case SearchSuggestionKind.TEXT:
      children = <TextItem item={item} query={query} testId={testId?.text} />;
      break;
    default:
      assertNever(kind);
      break;
  }

  return (
    <button
      className={cn('item', {highlight})}
      data-test-id={testId}
      onMouseDown={handleClick}
      tabIndex={-1}
      type='button'
    >
      {children}
    </button>
  );
}
