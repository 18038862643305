import BoxIcon from 'icons/box-line.svg';
import {useDevicevars} from 'lib/devicevars';
import {reverseUrl} from 'lib/router';
import React from 'react';
import {LinkButton, ButtonDirection} from '../Button';
import styles from './index.module.scss';
import {messages} from './messages';

export type Props = {
  direction?: ButtonDirection;
};

export function Orders({direction}: Props): React.ReactElement | null {
  const {shouldOrdersListShow} = useDevicevars();

  if (!shouldOrdersListShow) {
    return null;
  }

  return (
    <LinkButton
      direction={direction}
      href={reverseUrl.orders()}
      icon={<BoxIcon className={styles.icon} />}
      label={messages.label}
    />
  );
}
