import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import React, {useCallback} from 'react';
import {useIntl, defineMessages} from 'react-intl';
import {Button} from 'uikit/Button';

const messages = defineMessages({
  signUp: {
    defaultMessage: 'Sign Up',
    description: '[button] Page header. Navigation button label',
  },
});

type Props = {
  onClick(): void;
  shape?: 'classic' | 'round';
};

export function SignUpButton({onClick, shape}: Props): React.ReactElement {
  const intl = useIntl();
  const appEnv = useAppEnv();
  const analytcis = useAnalytics();
  const handleClick = useCallback(() => {
    onClick();
    analytcis.sendEvent({
      payload: {
        pageUrl: window.location.href,
        source: appEnv.currentPageName,
      },
      type: 'signUpClick',
    });
    analytcis.dataLayer({event: 'clickSignUpButton'});
  }, [onClick, appEnv.currentPageName, analytcis.sendEvent, analytcis.dataLayer]);

  return (
    <Button color='accent' onClick={handleClick} shape={shape}>
      {intl.formatMessage(messages.signUp)}
    </Button>
  );
}
