import {ButtonToggle} from 'components/ButtonToggle';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';
import {messages} from './messages';

type Props = {
  expanded: boolean;
  hideLabel?: string;
  onClick(): void;
  showLabel?: string;
};

export function ButtonDisclosure({expanded, onClick, ...props}: Props): React.ReactElement {
  const intl = useIntl();
  const hide = props.hideLabel || intl.formatMessage(messages.hide);
  const show = props.showLabel || intl.formatMessage(messages.show);

  return (
    <ButtonToggle expanded={expanded} onClick={onClick}>
      <span className={styles.content}>{expanded ? hide : show}</span>
    </ButtonToggle>
  );
}
