import {assertNever} from '@joomcode/deprecated-utils/types';
import cn from 'classnames';
import {User} from 'lib/user/types';
import React from 'react';
import {ButtonColor} from 'uikit/Button';
import {HeaderAction, HeaderTheme, HeaderShape} from '../types';
import {ButtonDirection} from '../ui/Button';
import {Cart} from '../ui/Cart';
import {Orders} from '../ui/Orders';
import {Profile} from '../ui/Profile';
import {SignInButton} from '../ui/SignInButton';
import {SignUpButton} from '../ui/SignUpButton';
import styles from './index.module.scss';

export type Props = {
  actions: HeaderAction[];
  cartItemsCount?: number;
  direction?: ButtonDirection;
  newHeader?: boolean;
  onSignIn(): void;
  onSignOut(): void;
  shape?: HeaderShape;
  theme: HeaderTheme;
  user: User;
};

const SIGN_IN_COLOR_BY_THEME: Record<HeaderTheme, ButtonColor> = {
  dark: 'outline-white',
  light: 'outline-black',
};

export function HeaderActions({
  user,
  theme,
  actions,
  cartItemsCount,
  onSignIn,
  onSignOut,
  shape,
  direction,
  newHeader,
}: Props): React.ReactElement {
  if (user.anonymous) {
    return (
      <>
        <div className={styles.desktop}>
          <div className={cn(styles.container, {[styles.newHeader]: newHeader})}>
            <SignInButton color={SIGN_IN_COLOR_BY_THEME[theme]} onClick={onSignIn} shape={shape} withText />
            <SignUpButton onClick={onSignIn} shape={shape} />
          </div>
        </div>
        <div className={styles.mobile}>
          <div className={styles.container}>
            <SignInButton color='accent' onClick={onSignIn} shape={shape} />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={styles.container}>
      {actions.map((action) => {
        switch (action) {
          case 'profile':
            return <Profile direction={direction} key={action} onSignIn={onSignIn} onSignOut={onSignOut} user={user} />;
          case 'cart':
            return <Cart count={cartItemsCount} direction={direction} key={action} />;
          case 'orders':
            return <Orders direction={direction} key={action} />;
          default:
            assertNever(action);
            return null;
        }
      })}
    </div>
  );
}
