import cn from 'classnames';
import {SearchBase, SearchRefState} from 'components/SearchBase';
import SearchIcon from 'icons/search.svg';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {SearchSuggestion} from 'lib/search/suggest';
import {TestIdProp} from 'lib/testing/types';
import {useRouter} from 'next/router';
import React, {useRef, useCallback} from 'react';
import {MessageDescriptor, defineMessages, useIntl} from 'react-intl';
import {Button, ButtonColor} from 'uikit/Button';
import {ImageSearchAddon} from './ImageSearchAddon';
import styles from './index.module.scss';
import {getUrlBySuggestion, getUrlBuilder} from './utils';

const DEFAULT_SEARCH_SUGGESTIONS: SearchSuggestion[] = [];

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'What are you looking for?',
    description: 'Hint in the search bar',
  },
});

export type SearchBarTestId = {
  clearButton: unknown;
  phraseInput: unknown;
  searchButton: unknown;
};

export type Props = TestIdProp<SearchBarTestId> & {
  initialPhrase?: string;
  isLockedImageSearch?: boolean;
  onClickImageSearch?(): void;
  onSearchByImage?: () => void;
  onSuggest?(phrase: string): void;
  placeholder?: MessageDescriptor;
  searchButtonColor?: ButtonColor;
  searchSuggestions?: SearchSuggestion[];
  searchSuggestionsPhrase?: string;
  shape?: 'classic' | 'round';
};

export function SearchBar({
  placeholder = messages.placeholder,
  searchSuggestionsPhrase = '',
  searchSuggestions = DEFAULT_SEARCH_SUGGESTIONS,
  searchButtonColor = 'black',
  initialPhrase = '',
  onClickImageSearch,
  onSuggest,
  testId,
  isLockedImageSearch,
  shape,
}: Props): React.ReactElement {
  const intl = useIntl();
  const router = useRouter();
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const searchRef = useRef<SearchRefState>();

  const handleSearchClick = useCallback(() => {
    if (searchRef.current) {
      searchRef.current.search();
      analytics.sendEvent({
        payload: {
          pageUrl: window.location.href,
          query: searchRef.current.value,
          source: appEnv.currentPageName,
        },
        type: 'searchBarEmit',
      });
    }
  }, [analytics.sendEvent, appEnv.currentPageName]);

  const handleSearchClear = useCallback(() => {
    if (searchRef.current) {
      searchRef.current.clear();
      analytics.sendEvent({
        payload: {
          pageUrl: window.location.href,
          query: searchRef.current.value,
          source: appEnv.currentPageName,
        },
        type: 'searchBarClear',
      });
    }
  }, [analytics.sendEvent, appEnv.currentPageName]);

  const handleSearchEmit = useCallback(
    (suggestion?: SearchSuggestion) => {
      if (suggestion) {
        const nextUrl = getUrlBySuggestion(suggestion);
        if (nextUrl) {
          router.push(nextUrl);
        }

        if (searchRef.current) {
          analytics.sendEvent({
            payload: {
              pageUrl: window.location.href,
              source: appEnv.currentPageName,
              suggestion,
            },
            type: 'searchBarSelect',
          });
        }
      } else {
        // If suggestion isn't passed (it could happen if input is empty)
        // we just redirect user to the root catalog page.
        router.push(getUrlBuilder()());
      }
    },
    [analytics.sendEvent, router.push, appEnv.currentPageName],
  );

  const handleSearchByImage = useCallback(({id}: {id: string}) => {
    searchRef.current?.clear();
    router.push(getUrlBuilder()({imageId: id}));
  }, []);

  return (
    <SearchBase
      initialPhrase={initialPhrase}
      onSearch={handleSearchEmit}
      onSuggest={onSuggest}
      ref={searchRef}
      searchSuggestions={searchSuggestions}
      searchSuggestionsPhrase={searchSuggestionsPhrase}
    >
      {(inputProps) => {
        return (
          <div className={cn(styles.inner, shape ? styles[shape] : undefined)} data-test-id={testId}>
            <input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...inputProps}
              className={styles.input}
              data-test-id={testId?.phraseInput}
              placeholder={intl.formatMessage(placeholder)}
            />
            <div className={styles.addons}>
              {Boolean(inputProps.value) && (
                <button
                  className={styles.clear}
                  data-test-id={testId?.clearButton}
                  onClick={handleSearchClear}
                  type='button'
                />
              )}
              <ImageSearchAddon
                isLockedImageSearch={isLockedImageSearch}
                isSmall={shape === 'classic'}
                onChange={handleSearchByImage}
                onClick={onClickImageSearch}
              />
            </div>
            <div className={styles.searchButton}>
              <Button
                color={searchButtonColor}
                onClick={handleSearchClick}
                shape={shape}
                size='medium'
                testId={testId?.searchButton}
                type='button'
              >
                <SearchIcon className={styles.searchIcon} />
              </Button>
            </div>
          </div>
        );
      }}
    </SearchBase>
  );
}
