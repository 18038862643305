import RightIcon from 'icons/chevron-right.svg';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {Icon} from '../Icon';
import {Template, Props as TemplateProps, TemplateTestId} from '../Template';
import {IconSize} from '../types';
import styles from './index.module.scss';

export type ItemTestId = {
  template: TemplateTestId;
};

export type Props = TestIdProp<ItemTestId> &
  Omit<TemplateProps, 'children' | 'leftIcon' | 'rightIcon' | 'testId'> & {
    iconSize?: IconSize;
    noArrow?: boolean;
    withIcon?: boolean;
  };

export function Item({
  buildUrl,
  category,
  withIcon,
  noArrow,
  onClick,
  testId,
  iconSize = 'medium',
}: Props): React.ReactElement {
  const rightIcon = category.hasPublicChildren && !noArrow && <RightIcon className={styles.arrow} />;
  const leftIcon = withIcon && <Icon category={category} size={iconSize} />;

  return (
    <Template
      buildUrl={buildUrl}
      category={category}
      leftIcon={leftIcon}
      onClick={onClick}
      rightIcon={rightIcon}
      testId={testId?.template}
    >
      {category.name}
    </Template>
  );
}
