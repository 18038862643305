import {isNotNullish} from '@joomcode/deprecated-utils/function';
import cnb from 'classnames/bind';
import React, {useMemo} from 'react';
import {CLASS_BY_THEME} from '../constants';
import {HeaderTheme} from '../types';
import styles from './index.module.scss';

const CLASS_LAYOUT_PART_BURGER = 'burger';
const CLASS_LAYOUT_PART_LOGO = 'logo';
const CLASS_LAYOUT_PART_SEARCH = 'search';
const CLASS_LAYOUT_PART_ACTIONS = 'actions';

const cn = cnb.bind(styles);

type Props = {
  actions?: React.ReactNode;
  burger?: React.ReactNode;
  catalog?: React.ReactNode;
  footer?: React.ReactNode;
  logo: React.ReactNode;
  search?: React.ReactNode;
  support?: React.ReactNode;
  theme?: HeaderTheme;
};

export function HeaderLayout({
  theme = 'light',
  logo,
  burger,
  search,
  catalog,
  actions,
  support,
  footer,
}: Props): React.ReactElement {
  const layoutClassName = useMemo(() => {
    // DO NOT CHANGE DIRECTION OF THE ITEMS in the array below.
    // Direction should be: burger, logo, search, actions.
    const direction: string[] = [];

    if (isNotNullish(burger)) {
      direction.push(CLASS_LAYOUT_PART_BURGER);
    }

    // Logo should be always presented
    direction.push(CLASS_LAYOUT_PART_LOGO);

    if (isNotNullish(search)) {
      direction.push(CLASS_LAYOUT_PART_SEARCH);
    }

    if (isNotNullish(actions)) {
      direction.push(CLASS_LAYOUT_PART_ACTIONS);
    }

    return `layout-${direction.join('-')}`;
  }, [burger, search, actions]);

  return (
    <header className={cn('container', CLASS_BY_THEME[theme])}>
      {support && <div className={styles.support}>{support}</div>}
      <div className={cn('content', layoutClassName)}>
        <div className={styles.logo}>{logo}</div>
        <div className={styles.search}>
          {catalog && <div className={styles.catalog}>{catalog}</div>}
          {search}
        </div>

        {burger && <div className={styles.burger}>{burger}</div>}
        {actions && <div className={styles.actions}>{actions}</div>}
      </div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </header>
  );
}
