import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {assertNever} from '@joomcode/deprecated-utils/types';
import {isFunction, isString} from 'lib/guards';
import {useKeyboardActiveIndex} from 'lib/hooks/useKeyboardActiveIndex';
import {normalizePhrase} from 'lib/search';
import {SearchSuggestion, SearchSuggestionKind, SearchSuggestionStore, SearchSuggestionText} from 'lib/search/suggest';
import {TestIdProp} from 'lib/testing/types';
import debounce from 'lodash/debounce';
import React, {useCallback, useState, useRef, useEffect, useImperativeHandle} from 'react';
import styles from './index.module.scss';
import {SearchSuggestions, SearchSuggestionsTestId} from './SearchSuggestions';
import {getItems, save, clear} from './storage';
import {getSearchSuggestionStoreId, createSearchSuggestionText} from './utils';

const DEBOUNCE_TIME = 300;
const DEFAULT_SEARCH_SUGGESTIONS: SearchSuggestion[] = [];

type ChangeHandler = React.ChangeEventHandler<HTMLInputElement>;
type KeyboardHandler = React.KeyboardEventHandler<HTMLInputElement>;

type ChildProps = {
  onBlur(): void;
  onChange: ChangeHandler;
  onFocus(): void;
  onKeyDown: KeyboardHandler;
  value: string;
};

export type SearchBaseTestId = {
  suggestions: SearchSuggestionsTestId;
};

type Props = TestIdProp<SearchBaseTestId> & {
  children(props: ChildProps): React.ReactNode;
  initialPhrase?: string;
  onSearch(searchSuggestion?: SearchSuggestion): void;
  onSuggest?(phrase: string): void;
  searchSuggestions?: SearchSuggestion[];
  searchSuggestionsPhrase?: string;
};

export type SearchRefState = {
  clear(): void;
  search(): void;
  value: string;
};

export const SearchBase = React.forwardRef<SearchRefState | undefined, Props>(
  (
    {
      children,
      onSuggest,
      onSearch,
      testId,
      initialPhrase,
      searchSuggestions = DEFAULT_SEARCH_SUGGESTIONS,
      searchSuggestionsPhrase = '',
    },
    ref,
  ) => {
    const [phrase, setPhrase] = useState('');
    const [focused, setFocused] = useState(false);
    const {isOpen, close, openWithPayload, payload = []} = usePopupState<SearchSuggestion[]>(false);

    const suggestionsRef = useRef<SearchSuggestion[]>(payload);
    const phraseRef = useRef<string>(phrase);
    suggestionsRef.current = payload;
    phraseRef.current = phrase;

    useEffect(() => {
      if (focused) {
        if (searchSuggestions.length && searchSuggestionsPhrase === phrase) {
          openWithPayload(searchSuggestions);
        }
      }
    }, [searchSuggestions]);

    useEffect(() => {
      if (isString(initialPhrase)) {
        setPhrase(initialPhrase);
      }
    }, [initialPhrase]);

    const setStorageSuggestions = useCallback(() => {
      const storageItems = getItems();
      if (storageItems.length) {
        openWithPayload(storageItems);
      }
    }, []);

    const clearRecent = useCallback(() => {
      clear();
      close();
    }, []);

    const selectStore = useCallback(
      (searchSuggestion: SearchSuggestionStore) => {
        if (getSearchSuggestionStoreId(searchSuggestion)) {
          close();
          save(searchSuggestion);
          setPhrase('');
          onSearch(searchSuggestion);
        }
      },
      [onSearch],
    );

    const selectText = useCallback(
      (searchSuggestion: SearchSuggestionText) => {
        if (searchSuggestion.searchParams.phrase) {
          close();
          save(searchSuggestion);
          setPhrase(searchSuggestion.searchParams.phrase);
          onSearch(searchSuggestion);
        }
      },
      [onSearch],
    );

    const select = useCallback(
      (searchSuggestion: SearchSuggestion) => {
        const {kind} = searchSuggestion;
        switch (kind) {
          case SearchSuggestionKind.STORE: {
            selectStore(searchSuggestion);
            break;
          }
          case SearchSuggestionKind.TEXT: {
            selectText(searchSuggestion);
            break;
          }
          default:
            assertNever(kind);
            break;
        }
      },
      [selectStore, selectText],
    );

    const search = useCallback(() => {
      if (phraseRef.current) {
        const normalizedPhrase = normalizePhrase(phraseRef.current);
        const searchSuggestion = createSearchSuggestionText(normalizedPhrase);
        select(searchSuggestion);
      } else {
        close();
        onSearch();
      }
    }, [onSearch, select, close]);

    const onSuggestDebounced = useCallback(
      debounce((phraseValue: string) => {
        if (onSuggest && phraseValue) {
          onSuggest(phraseValue);
        }
      }, DEBOUNCE_TIME),
      [onSuggest],
    );

    const handleBlur = useCallback(() => {
      close();
      setFocused(false);
    }, []);

    const handleFocus = useCallback(() => {
      setFocused(true);

      if (phraseRef.current) {
        onSuggestDebounced(phraseRef.current);
      } else {
        setStorageSuggestions();
      }
    }, [onSuggestDebounced]);

    const handleChange = useCallback<ChangeHandler>(
      (event) => {
        const {value} = event.target;

        close();
        setPhrase(value);
        onSuggestDebounced(value);

        if (!value) {
          setStorageSuggestions();
        }
      },
      [onSuggestDebounced],
    );

    const handleEnter = useCallback((activeIndex: number) => {
      const suggestion = suggestionsRef.current[activeIndex];
      if (suggestion) {
        select(suggestion);
      } else {
        search();
      }
    }, []);

    const handleSelectSuggestion = useCallback(
      (searchSuggestion: SearchSuggestion) => {
        select(searchSuggestion);
      },
      [select],
    );

    const {reset, activeIndex, handleKeyDown} = useKeyboardActiveIndex({
      lastIndex: suggestionsRef.current.length - 1,
      onEnter: handleEnter,
    });

    useImperativeHandle(
      ref,
      () => ({
        clear: () => setPhrase(''),
        search,
        value: phrase,
      }),
      [search, phrase],
    );

    return (
      <div className={styles.container} data-test-id={testId}>
        {isFunction(children) &&
          children({
            onBlur: handleBlur,
            onChange: handleChange,
            onFocus: handleFocus,
            onKeyDown: handleKeyDown,
            value: phrase,
          })}
        {isOpen && (
          <div onMouseEnter={reset}>
            <SearchSuggestions
              activeIndex={activeIndex}
              isRecentRequests={!phrase}
              items={suggestionsRef.current}
              onClearRecentRequests={clearRecent}
              onSelect={handleSelectSuggestion}
              query={phrase}
              testId={testId?.suggestions}
            />
          </div>
        )}
      </div>
    );
  },
);
