import Burger from 'icons/burger.svg';
import Close from 'icons/close.svg';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, ButtonColor, ButtonShape} from 'uikit/Button';
import {HeaderTheme} from '../types';
import styles from './index.module.scss';

export type Props = {
  expanded: boolean;
  onClick?(): void;
  shape?: ButtonShape;
  theme?: HeaderTheme;
};

const BUTTON_COLOR_BY_THEME: Record<HeaderTheme, ButtonColor> = {
  dark: 'white',
  light: 'black',
};

export function HeaderCatalogButton({onClick, expanded, shape = 'round', theme = 'light'}: Props): React.ReactElement {
  return (
    <Button
      color={BUTTON_COLOR_BY_THEME[theme]}
      iconLeft={expanded ? <Close className={styles.icon} /> : <Burger className={styles.icon} />}
      onClick={onClick}
      shape={shape}
    >
      <FormattedMessage defaultMessage='Catalog' description='Button to open all Catalog in the page header' />
    </Button>
  );
}
