import {CategoriesCatalog} from 'components/CategoriesCatalog';
import {Color as CategoriesCatalogColor} from 'components/CategoriesCatalog/types';
import {HeaderCatalogButton} from 'components/Header/HeaderCatalogButton';
import {HeaderLayout} from 'components/Header/HeaderLayout';
import {HeaderLogo} from 'components/Header/HeaderLogo';
import {HeaderAction, HeaderTheme} from 'components/Header/types';
import {Reducer} from 'components/Layout/Reducer';
import {Category} from 'lib/category/types';
import React from 'react';
import {HeaderActions} from '../../HeaderActions';
import {HeaderSearchBar} from '../../HeaderSearchBar';
import styles from './index.module.scss';

const CATEGORIES_CATALOG_COLOR_BY_THEME: Record<HeaderTheme, CategoriesCatalogColor> = {
  dark: 'white',
  light: 'black',
};

type Props = {
  actions: HeaderAction[];
  categories: Category[];
  onClose(): void;
  theme: HeaderTheme;
};

export function DesktopLayout({actions, theme, onClose, categories}: Props): React.ReactElement {
  return (
    <Reducer>
      <div className={styles.content}>
        <HeaderLayout
          actions={<HeaderActions actions={actions} shape='classic' theme={theme} />}
          catalog={<HeaderCatalogButton expanded onClick={onClose} shape='classic' theme={theme} />}
          logo={<HeaderLogo />}
          search={<HeaderSearchBar shape='classic' theme={theme} />}
          theme={theme}
        />
        <CategoriesCatalog categories={categories} color={CATEGORIES_CATALOG_COLOR_BY_THEME[theme]} />
      </div>
    </Reducer>
  );
}
