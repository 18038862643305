import cn from 'classnames';
import {Icon} from 'components/Categories/Icon';
import {Link} from 'components/Link';
import BurgerIcon from 'icons/burger.svg';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {Category} from 'lib/category/types';
import {reverseUrl} from 'lib/router';
import {TestIdProp} from 'lib/testing/types';
import React, {MouseEventHandler, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {useNavLinks} from '../hooks/useNavLinks';
import styles from './index.module.scss';

export type NavigationTestId = {
  allButton: unknown;
  categoryLink: {
    [key: string]: unknown;
  };
};

type Props = TestIdProp<NavigationTestId> & {
  categories: Category[];
  onAllClick?: () => void;
  onCategoryClick?: () => void;
  openLinkInNewTab?: boolean;
  withIcons?: boolean;
};

export function HeaderNavigation({
  categories,
  onAllClick,
  onCategoryClick,
  testId,
  withIcons,
  openLinkInNewTab,
}: Props): React.ReactElement {
  const analytics = useAnalytics();
  const appEnv = useAppEnv();
  const navLinks = useNavLinks();

  const handleCategoryClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      analytics.sendEvent({
        payload: {
          categoryId: event.currentTarget.dataset.id!,
          pageUrl: window.location.href,
          position: 'headerShortList',
          source: appEnv.currentPageName,
        },
        type: 'categoryClick',
      });

      onCategoryClick?.();
    },
    [analytics, appEnv, onCategoryClick],
  );

  return (
    <nav className={styles.shortList} data-test-id={testId}>
      <div className={cn(styles.inner, {[styles.withIcons]: withIcons})}>
        {Boolean(onAllClick) && (
          <button className={styles.all} data-test-id={testId?.allButton} onClick={onAllClick} type='button'>
            <BurgerIcon className={styles.icon} />
            <span>
              <FormattedMessage
                defaultMessage='All categories'
                description='Button to open all categories in the page header'
              />
            </span>
          </button>
        )}
        {navLinks.length > 0 &&
          navLinks.map((link) => (
            <Link
              className={cn(styles.link, {[styles.externalLink]: !withIcons, [styles.withIcon]: withIcons})}
              href={link.href}
              key={link.message}
              target={openLinkInNewTab ? '_blank' : '_self'}
            >
              {withIcons && <div className={styles.linkIcon}>{link.icon}</div>}
              {link.message}
            </Link>
          ))}
        {categories.map((category) => (
          <Link
            className={cn(styles.link, styles.categoryLink, {[styles.withIcon]: withIcons})}
            data-id={category.id}
            href={reverseUrl.search({categoryId: category.id})}
            key={category.id}
            onClick={handleCategoryClick}
            testId={testId?.categoryLink[category.id]}
          >
            {withIcons && (
              <div className={styles.linkIcon}>
                <Icon category={category} />
              </div>
            )}
            {category.name}
          </Link>
        ))}
      </div>
    </nav>
  );
}
