import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import styles from './index.module.scss';

const messages = defineMessages({
  message: {
    defaultMessage: 'SVG, PNG, JPG or GIF (max. 3MB)',
    description: 'Search bar. Search by image addon. Hint message',
  },
  title: {
    defaultMessage: 'Search by image:',
    description: 'Search bar. Search by image addon. Hint title',
  },
});

export function TooltipDefaultContent(): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.hint}>
      <div className={styles.title}>{intl.formatMessage(messages.title)}</div>
      <div className={styles.message}>{intl.formatMessage(messages.message)}</div>
    </div>
  );
}
