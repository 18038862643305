import {isFilterValue} from 'lib/filtersAndSorting';
import {FilterType, TreeFilterValue} from 'lib/filtersAndSorting/types';
import {SearchSuggestionKind, SearchSuggestionStore, SearchSuggestionText} from 'lib/search/suggest';

export function getSearchSuggestionStoreId(searchSuggestion: SearchSuggestionStore): string | undefined {
  const {searchParams} = searchSuggestion;
  if (searchParams.filters?.length) {
    const storesFilter = searchParams.filters.find(
      (filter) => filter.id === 'storeId' && filter.type === FilterType.STORES,
    );

    if (isFilterValue<TreeFilterValue>(storesFilter, FilterType.STORES)) {
      return storesFilter.items?.[0].id;
    }
  }

  return undefined;
}

export function createSearchSuggestionText(phrase: string): SearchSuggestionText {
  return {
    id: phrase,
    kind: SearchSuggestionKind.TEXT,
    searchParams: {
      phrase,
    },
    title: phrase,
  };
}
