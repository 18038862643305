import ChevronLeftIcon from 'icons/chevron-left.svg';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {Template, Props as TemplateProps, TemplateTestId} from '../Template';
import styles from './index.module.scss';

export type ParentTestId = {
  template: TemplateTestId;
};

export type Props = TestIdProp<ParentTestId> & Omit<TemplateProps, 'children' | 'leftIcon' | 'rightIcon'>;

export function Parent({buildUrl, category, onClick, testId}: Props): React.ReactElement {
  return (
    <Template
      buildUrl={buildUrl}
      category={category}
      leftIcon={<ChevronLeftIcon className={styles.icon} />}
      onClick={onClick}
      testId={testId?.template}
    >
      {category.name}
    </Template>
  );
}
