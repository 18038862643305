import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  category: {
    defaultMessage: 'View all products in this category',
    description: '[button] Search. Categories sidebar. Apply category button',
  },
  root: {
    defaultMessage: 'View all products',
    description: '[button] Search. Categories sidebar. Apply button',
  },
});
