import {LogoLink} from 'components/LogoLink';
import React from 'react';
import styles from './index.module.scss';

export function HeaderLogo(): React.ReactElement {
  return (
    <div className={styles.container}>
      <LogoLink />
    </div>
  );
}
