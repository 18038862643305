import {SearchBar} from 'components/SearchBar';
import {SearchSuggestion} from 'lib/search/suggest';
import React from 'react';
import {ButtonColor} from 'uikit/Button';
import {HeaderTheme, HeaderShape} from '../types';

type Props = {
  isLockedImageSearch?: boolean;
  onClickImageSearch?: () => void;
  onSearchByImage?: () => void;
  onSearchPhraseSuggest: (phrase: string) => void;
  searchPhrase?: string;
  searchSuggestions?: SearchSuggestion[];
  searchSuggestionsPhrase?: string;
  shape?: HeaderShape;
  theme: HeaderTheme;
};

const SEARCH_BUTTON_COLOR_BY_THEME: Record<HeaderTheme, ButtonColor> = {
  dark: 'accent',
  light: 'black',
};

export function HeaderSearchBar({
  theme,
  searchPhrase,
  searchSuggestions,
  searchSuggestionsPhrase,
  shape,
  onSearchByImage,
  onSearchPhraseSuggest,
  onClickImageSearch,
  isLockedImageSearch,
}: Props): React.ReactElement {
  return (
    <SearchBar
      initialPhrase={searchPhrase}
      isLockedImageSearch={isLockedImageSearch}
      onClickImageSearch={onClickImageSearch}
      onSearchByImage={onSearchByImage}
      onSuggest={onSearchPhraseSuggest}
      searchButtonColor={SEARCH_BUTTON_COLOR_BY_THEME[theme]}
      searchSuggestions={searchSuggestions}
      searchSuggestionsPhrase={searchSuggestionsPhrase}
      shape={shape}
    />
  );
}
