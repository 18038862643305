import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import styles from './index.module.scss';

const messages = defineMessages({
  message: {
    defaultMessage: 'Please select an image that is smaller than 3MB (in SVG, PNG, JPG, or GIF format)',
    description: 'Search bar. Search by image addon. Hint message',
  },
  title: {
    defaultMessage: 'File size is too large',
    description: 'Search bar. Search by image addon. Hint title',
  },
});

export function TooltipSizeTooLargeContent(): React.ReactElement {
  const intl = useIntl();

  return (
    <div className={styles.hint}>
      <div className={styles.title}>{intl.formatMessage(messages.title)}</div>
      <div className={styles.message}>{intl.formatMessage(messages.message)}</div>
    </div>
  );
}
