import cn from 'classnames';
import {Category} from 'lib/category/types';
import chunk from 'lodash/chunk';
import React, {useMemo} from 'react';
import {CategoryBlock} from '../CategoryBlock';
import {Color} from '../types';
import styles from './index.module.scss';

type Props = {
  categories: Category[];
  color?: Color;
};

const DESKTOP_COLUMNS_COUNT = 3;
const TABLET_COLUMNS_COUNT = 2;

export function CategoryColumns({categories, color = 'black'}: Props): React.ReactElement {
  const [desktopColumns, tabletColumns] = useMemo(
    () => [
      chunk(categories, Math.ceil(categories.length / DESKTOP_COLUMNS_COUNT)),
      chunk(categories, Math.ceil(categories.length / TABLET_COLUMNS_COUNT)),
    ],
    [categories],
  );

  return (
    <div className={styles.container}>
      {desktopColumns.map((column, index) => (
        <div className={cn(styles.column, styles.desktop)} key={index}>
          {column.map((category) => (
            <CategoryBlock category={category} color={color} key={category.id} />
          ))}
        </div>
      ))}
      {tabletColumns.map((column, index) => (
        <div className={cn(styles.column, styles.tablet)} key={index}>
          {column.map((category) => (
            <CategoryBlock category={category} color={color} key={category.id} />
          ))}
        </div>
      ))}
    </div>
  );
}
