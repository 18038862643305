import cn from 'classnames';
import React from 'react';
import Chevron from './chevron.svg';
import styles from './index.module.scss';

type Props = {
  expanded?: boolean;
};

export function Arrow({expanded}: Props): React.ReactElement {
  return (
    <div className={cn(styles.root, {[styles.expanded]: expanded})}>
      <Chevron />
    </div>
  );
}
