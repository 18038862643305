import CartIcon from 'icons/cart.svg';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import {reverseUrl} from 'lib/router';
import React, {useCallback} from 'react';
import {LinkButton, ButtonDirection} from '../Button';
import {messages} from './messages';

export type Props = {
  count: number | undefined;
  direction?: ButtonDirection;
};

export function Cart({count, direction}: Props): React.ReactElement {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();

  const handleClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        itemsCount: count ?? -1,
        pageUrl: window.location.href,
        source: appEnv.currentPageName,
      },
      type: 'cartClick',
    });
  }, [appEnv, analytics.sendEvent, count]);

  return (
    <LinkButton
      count={count}
      direction={direction}
      href={reverseUrl.cart({source: appEnv.currentPageName})}
      icon={<CartIcon />}
      label={messages.label}
      onClick={handleClick}
    />
  );
}
