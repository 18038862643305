import cnb from 'classnames/bind';
import {HeaderAction, HeaderTheme} from 'components/Header/types';
import {Category} from 'lib/category/types';
import {useRouter} from 'next/router';
import React, {useEffect} from 'react';
import {ModalOverlay} from 'uikit/ModalOverlay';
import {DesktopLayout} from './DesktopLayout';
import styles from './index.module.scss';
import {MobileLayout} from './MobileLayout';

const cn = cnb.bind(styles);

type Props = {
  actions: HeaderAction[];
  categories: Category[];
  onClose(): void;
  theme: HeaderTheme;
};

export function HeaderCatalog({onClose, categories, theme, actions}: Props): React.ReactElement {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', onClose);
    return () => {
      router.events.off('routeChangeStart', onClose);
    };
  }, [onClose]);

  return (
    <ModalOverlay focusLock scrollLock>
      <div className={cn(styles.container, `theme-${theme}`)}>
        <div className={styles.desktop}>
          <DesktopLayout actions={actions} categories={categories} onClose={onClose} theme={theme} />
        </div>
        <div className={styles.mobile}>
          <MobileLayout actions={actions} onClose={onClose} theme={theme} />
        </div>
      </div>
    </ModalOverlay>
  );
}
