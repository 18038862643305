import {Image} from 'components/Image';
import {ImageBundle} from 'lib/image/types';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  image?: ImageBundle;
  sizes?: number | string;
};

export function Avatar({image, sizes = 32}: Props): React.ReactElement {
  return (
    <div className={styles.avatar}>
      <div className={styles.inner}>
        {image ? <Image className={styles.image} sizes={sizes} src={image} /> : <div className={styles.empty} />}
      </div>
    </div>
  );
}
