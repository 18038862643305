import cn from 'classnames';
import React from 'react';
import styles from './index.module.scss';

type Props = {
  onBack?(): void;
  onClose?(): void;
  title: React.ReactNode;
  withBack?: boolean;
  withClose?: boolean;
};

export function NavigationHeader({
  title,
  onBack,
  onClose,
  withClose = false,
  withBack = false,
}: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      {withBack && onBack && <button className={cn(styles.button, styles.back)} onClick={onBack} type='button' />}
      <div className={styles.title}>{title}</div>
      {withClose && onClose && <button className={cn(styles.button, styles.close)} onClick={onClose} type='button' />}
    </div>
  );
}
