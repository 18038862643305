import cnb from 'classnames/bind';
import {Categories} from 'components/Categories';
import {Link} from 'components/Link';
import {Category} from 'lib/category/types';
import {reverseUrl} from 'lib/router';
import React, {useCallback} from 'react';
import {Color} from '../types';
import styles from './index.module.scss';

const cn = cnb.bind(styles);

type Props = {
  category: Category;
  color?: Color;
  isActive?: boolean;
  onSelect(category: Category): void;
};

export function CategoryRootButton({color = 'black', category, isActive = false, onSelect}: Props): React.ReactElement {
  const handleSelect = useCallback(() => {
    onSelect(category);
  }, [onSelect, category]);

  return (
    <Link
      className={cn('link', isActive && 'active', `color-${color}`)}
      href={reverseUrl.search({categoryId: category.id})}
      onFocus={handleSelect}
      onMouseEnter={handleSelect}
    >
      <Categories.Icon category={category} size='medium' />
      {category.name}
    </Link>
  );
}
