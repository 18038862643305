import {usePopupState} from '@joomcode/deprecated-utils/react/usePopupState';
import {DEFAULT_ACTIONS} from 'components/Header/constants';
import {HeaderBurgerMenu} from 'components/Header/HeaderBurgerMenu';
import {HeaderCatalogButton} from 'components/Header/HeaderCatalogButton';
import {HeaderLayout} from 'components/Header/HeaderLayout';
import {HeaderLogo} from 'components/Header/HeaderLogo';
import {HeaderNavigation} from 'components/Header/HeaderNavigation';
import {HeaderAction, HeaderTheme} from 'components/Header/types';
import {Category} from 'lib/category/types';
import {useStore} from 'lib/effector';
import {$categories} from 'models/categories';
import React, {useEffect} from 'react';
import {HeaderActions} from './HeaderActions';
import {HeaderCatalog} from './HeaderCatalog';
import {HeaderSearchBar} from './HeaderSearchBar';

const DEFAULT_CATEGORIES: Category[] = [];

export type VisibilityProps = {
  hideBottomNav?: boolean;
  hideCatalogButton?: boolean;
  hideSearch?: boolean;
};

export type Props = VisibilityProps & {
  actions?: HeaderAction[];
  categories?: Category[];
  theme?: HeaderTheme;
};

export function HeaderContainer({
  theme = 'light',
  actions = DEFAULT_ACTIONS,
  categories: initialCategories,
  hideSearch = false,
  hideBottomNav = false,
  hideCatalogButton = false,
}: Props): React.ReactElement {
  const {categories: rootCategories = DEFAULT_CATEGORIES} = useStore($categories);
  const categories = initialCategories ?? rootCategories;

  const catalogPopup = usePopupState(false);
  const showActions = actions.length > 0;
  const withCategories = categories.length > 0;

  useEffect(() => {
    if (!withCategories) {
      catalogPopup.close();
    }
  }, [withCategories]);

  return (
    <>
      <HeaderLayout
        actions={showActions && <HeaderActions actions={actions} shape='classic' theme={theme} />}
        burger={withCategories && <HeaderBurgerMenu expanded={catalogPopup.isOpen} onClick={catalogPopup.toggle} />}
        catalog={
          !hideCatalogButton && (
            <HeaderCatalogButton expanded={catalogPopup.isOpen} onClick={catalogPopup.toggle} shape='classic' />
          )
        }
        footer={!hideBottomNav && <HeaderNavigation categories={categories} withIcons />}
        logo={<HeaderLogo />}
        search={!hideSearch && <HeaderSearchBar shape='classic' theme={theme} />}
        theme={theme}
      />
      {catalogPopup.isOpen && (
        <HeaderCatalog actions={actions} categories={categories} onClose={catalogPopup.close} theme={theme} />
      )}
    </>
  );
}
