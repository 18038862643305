import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import React from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

type Props =
  | {
      children: React.ReactNode;
      message?: undefined;
    }
  | {
      children?: undefined;
      message: ExtMessageDescriptor;
    };

export function VisuallyHidden({children, message}: Props): React.ReactElement {
  const intl = useIntl();

  return <span className={styles.visuallyHidden}>{children || buildMessage(intl, message)}</span>;
}
