import {HeaderBurgerMenu} from 'components/Header/HeaderBurgerMenu';
import {HeaderLayout} from 'components/Header/HeaderLayout';
import {HeaderLogo} from 'components/Header/HeaderLogo';
import {useNavLinks} from 'components/Header/hooks/useNavLinks';
import {HeaderAction, HeaderTheme} from 'components/Header/types';
import {NavLink} from 'components/Header/ui/NavLink';
import {SignInButton} from 'components/Header/ui/SignInButton';
import {SignUpButton} from 'components/Header/ui/SignUpButton';
import {HeaderActions} from 'containers/HeaderContainer/HeaderActions';
import {SearchCategoryTree} from 'containers/SearchCategoryTree';
import {useAppEnv} from 'lib/appEnv';
import {useStore, useEvent} from 'lib/effector';
import {openSsAuthDialog} from 'models/ssRegistrationDialog';
import {$user} from 'models/user';
import React, {useCallback} from 'react';
import {ButtonColor} from 'uikit/Button';
import styles from './index.module.scss';

type Props = {
  actions: HeaderAction[];
  onClose(): void;
  theme: HeaderTheme;
};

const SIGN_IN_COLOR_BY_THEME: Record<HeaderTheme, ButtonColor> = {
  dark: 'outline-white',
  light: 'outline-black',
};

export function MobileLayout({actions, theme, onClose}: Props): React.ReactElement {
  const appEnv = useAppEnv();
  const navLinks = useNavLinks();
  const {anonymous} = useStore($user);
  const signInEvent = useEvent(openSsAuthDialog);
  const handleSignIn = useCallback(() => {
    signInEvent(appEnv.currentPageName);
  }, [appEnv, signInEvent]);

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <HeaderLayout
          actions={<HeaderActions actions={actions} shape='classic' theme={theme} />}
          burger={<HeaderBurgerMenu expanded onClick={onClose} />}
          logo={<HeaderLogo />}
          theme={theme}
        />
      </div>
      <div className={styles.main}>
        {anonymous && (
          <div className={styles.actions}>
            <SignInButton color={SIGN_IN_COLOR_BY_THEME[theme]} onClick={handleSignIn} shape='classic' withText />
            <SignUpButton onClick={handleSignIn} shape='classic' />
          </div>
        )}
        <SearchCategoryTree position='sidebar' />
      </div>
      <div className={styles.footer}>
        {navLinks.map((link) => (
          <NavLink href={link.href} icon={link.icon} key={link.href} label={link.message} />
        ))}
      </div>
    </div>
  );
}
