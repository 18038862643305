import {CategoryIndex, Category} from './types';

export function makeCategoryIndex(categories: Category[], categoryIndex: CategoryIndex = {}): CategoryIndex {
  categories.forEach((category) => {
    if (category.id) {
      // eslint-disable-next-line no-param-reassign
      categoryIndex[category.id] = category;
    }

    if (Array.isArray(category.children)) {
      makeCategoryIndex(category.children, categoryIndex);
    }
  });

  return categoryIndex;
}
