import {HeaderSearchBar as HeaderSearchBarBase} from 'components/Header/HeaderSearchBar';
import {HeaderShape, HeaderTheme} from 'components/Header/types';
import {useAnalytics} from 'lib/analytics';
import {useDevicevars} from 'lib/devicevars';
import {useEvent, useStore} from 'lib/effector';
import {$searchPhrase} from 'models/search';
import {$searchSuggestions, searchPhraseSuggestFx} from 'models/search/suggest';
import {openSsAuthDialog} from 'models/ssRegistrationDialog';
import {$user} from 'models/user';
import React, {useCallback} from 'react';

type Props = {
  shape?: HeaderShape;
  theme: HeaderTheme;
};

export function HeaderSearchBar({theme, shape}: Props): React.ReactElement {
  const {searchSuggestions, searchSuggestionsPhrase} = useStore($searchSuggestions);
  const searchPhrase = useStore($searchPhrase);
  const {anonymous} = useStore($user);
  const signInEvent = useEvent(openSsAuthDialog);

  const analytics = useAnalytics();
  const {unauthUsersLockedFeatures} = useDevicevars();
  const isLockedImageSearch = anonymous && Boolean(unauthUsersLockedFeatures);

  const handleSuggestSearchQuery = useEvent(searchPhraseSuggestFx);
  const handleClickImageSearch = useCallback(() => {
    if (isLockedImageSearch) {
      analytics.sendEvent({
        payload: {
          pageUrl: window.location.href,
          source: 'searchImage',
        },
        type: 'signUpOpen',
      });

      signInEvent('searchImage');
    }
  }, [signInEvent]);

  return (
    <HeaderSearchBarBase
      isLockedImageSearch={isLockedImageSearch}
      onClickImageSearch={handleClickImageSearch}
      onSearchPhraseSuggest={handleSuggestSearchQuery}
      searchPhrase={searchPhrase}
      searchSuggestions={searchSuggestions}
      searchSuggestionsPhrase={searchSuggestionsPhrase}
      shape={shape}
      theme={theme}
    />
  );
}
