import {SearchSuggestion} from 'lib/search/suggest';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import styles from './index.module.scss';
import {Item, ItemTestId} from './Item';

export type SearchSuggestionsTestId = {
  clear: unknown;
  item: ItemTestId;
};

type Props = TestIdProp<SearchSuggestionsTestId> & {
  activeIndex: number;
  isRecentRequests?: boolean;
  items: SearchSuggestion[];
  onClearRecentRequests(): void;
  onSelect(phrase: SearchSuggestion): void;
  query: string;
};

export function SearchSuggestions({
  query,
  items,
  testId,
  activeIndex,
  isRecentRequests = false,
  onClearRecentRequests,
  onSelect,
}: Props): React.ReactElement {
  return (
    <div className={styles.container} data-test-id={testId}>
      {isRecentRequests && (
        <div className={styles.header}>
          <div className={styles.title}>
            <FormattedMessage
              defaultMessage='Recent searches'
              description='Search bar hint list: header for list of recent queries'
            />
          </div>
          <button
            className={styles.clearButton}
            data-test-id={testId?.clear}
            onMouseDown={onClearRecentRequests}
            type='button'
          />
        </div>
      )}
      <div className={styles.items}>
        {items.map((item, index) => (
          <Item
            highlight={index === activeIndex}
            item={item}
            key={item.id}
            onSelect={onSelect}
            query={query}
            testId={testId?.item}
          />
        ))}
      </div>
    </div>
  );
}
