import {SearchSuggestion} from 'lib/search/suggest';
import {localStorage} from 'lib/storage';

const SEARCH_SUGGESTIONS_KEY = 'searchPhrases';
const SEARCH_SUGGESTIONS_VERSION = 2;
const SEARCH_SUGGESTIONS_MAX_LENGTH = 10;

export function getItems(): SearchSuggestion[] {
  return localStorage.getItem<SearchSuggestion[]>(SEARCH_SUGGESTIONS_KEY, SEARCH_SUGGESTIONS_VERSION) || [];
}

export function save(searchSuggestion: SearchSuggestion): void {
  let searchSuggestions = getItems();

  if (searchSuggestions.length) {
    searchSuggestions = searchSuggestions.filter((item) => item.id !== searchSuggestion.id);
    searchSuggestions.unshift(searchSuggestion);

    if (searchSuggestions.length > SEARCH_SUGGESTIONS_MAX_LENGTH) {
      searchSuggestions = searchSuggestions.slice(0, SEARCH_SUGGESTIONS_MAX_LENGTH);
    }
  } else {
    searchSuggestions.push(searchSuggestion);
  }

  localStorage.setItem(SEARCH_SUGGESTIONS_KEY, searchSuggestions, SEARCH_SUGGESTIONS_VERSION);
}

export function clear(): void {
  localStorage.removeItem(SEARCH_SUGGESTIONS_KEY);
}
