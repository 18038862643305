import cn from 'classnames/bind';
import {Link, LinkProps} from 'components/Link';
import {isNumber} from 'lib/guards';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';
import styles from './index.module.scss';

const cnb = cn.bind(styles);

const MIN_COUNT_VALUE = 1;
const MAX_COUNT_VALUE = 99;
const MAX_COUNT_TITLE = `${MAX_COUNT_VALUE}+`;

export type ButtonDirection = 'vertical' | 'horizontal';
export type ButtonTestId = {
  count: unknown;
  icon: unknown;
  label: unknown;
};

type CoreProps = TestIdProp<ButtonTestId> & {
  count?: number;
  direction?: ButtonDirection;
  icon: React.ReactElement;
  label: React.ReactElement | MessageDescriptor;
};

function Core({icon, label, count, testId, direction = 'vertical'}: CoreProps): React.ReactElement {
  return (
    <div className={cnb(styles.container, direction)}>
      <div className={styles.icon} data-test-id={testId?.icon}>
        {isNumber(count) &&
          count >= MIN_COUNT_VALUE &&
          (direction === 'horizontal' && label ? (
            <div className={styles.notification} />
          ) : (
            <div className={styles.count} data-test-id={testId?.count}>
              {count > MAX_COUNT_VALUE ? MAX_COUNT_TITLE : count}
            </div>
          ))}
        {icon}
      </div>
      <div className={styles.label} data-test-id={testId?.label}>
        {React.isValidElement(label) ? label : <FormattedMessage {...label} />}
      </div>
    </div>
  );
}

export type ButtonProps = CoreProps & {
  onClick?: () => void;
};

export function Button({onClick, testId, ...restProps}: ButtonProps): React.ReactElement {
  return (
    <button className={styles.button} data-test-id={testId} onClick={onClick} type='button'>
      <Core {...restProps} testId={testId} />
    </button>
  );
}

export type LinkButtonProps = CoreProps & Pick<LinkProps, 'href' | 'target' | 'onClick'>;

export function LinkButton({href, target, onClick, testId, ...restProps}: LinkButtonProps): React.ReactElement {
  return (
    <Link className={styles.link} href={href} onClick={onClick} target={target}>
      <Core {...restProps} testId={testId} />
    </Link>
  );
}
