import cn from 'classnames';
import React, {useCallback, forwardRef} from 'react';
import {Arrow} from './Arrow';
import styles from './index.module.scss';

type Props = {
  children?: React.ReactNode;
  expanded: boolean;
  onClick: (expanded: boolean) => void;
};

export const ButtonToggle = forwardRef<HTMLButtonElement, Props>(({expanded, children, onClick}: Props, ref) => {
  const handleClickToggle: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation();
      onClick(!expanded);
    },
    [expanded, onClick],
  );

  return (
    <button
      className={cn(styles.root, {[styles.expanded]: expanded})}
      onClick={handleClickToggle}
      ref={ref}
      type='button'
    >
      {children}
      <Arrow expanded={expanded} />
    </button>
  );
});
