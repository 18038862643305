import React from 'react';
import {Template, Props as TemplateProps} from '../Template';
import LeftIcon from './arrowLeft.svg';
import styles from './index.module.scss';

export type Props = Omit<TemplateProps, 'children' | 'leftIcon' | 'rightIcon'> & {
  withArrow?: boolean;
};

export function Current({buildUrl, onClick, withArrow, category}: Props): React.ReactElement {
  const leftIcon = withArrow && <LeftIcon className={styles.arrow} />;

  return (
    <Template buildUrl={buildUrl} category={category} leftIcon={leftIcon} onClick={onClick}>
      <span className={styles.name}>{category.name}</span>
    </Template>
  );
}
