import {MutableRefObject, useEffect} from 'react';

/**
 * Reference implementation: https://github.com/airbnb/react-outside-click-handler/blob/master/src/OutsideClickHandler.jsx
 */
export function useClickOutside<E extends HTMLElement | null>(
  onClick: () => void,
  avoidClickNodeRefs: MutableRefObject<E>[],
  {
    disabled,
    /**
     * `useCapture` is set to true by default so that a `stopPropagation` in the
     * children will not prevent all outside click handlers from firing
     */
    useCapture = true,
  }: {
    disabled?: boolean;
    useCapture?: boolean;
  } = {},
) {
  useEffect(() => {
    if (disabled || avoidClickNodeRefs.some((ref) => !ref.current)) {
      return undefined;
    }

    function onMouseUp(event: MouseEvent) {
      document.removeEventListener('mouseup', onMouseUp, useCapture);

      if (avoidClickNodeRefs.every((ref) => ref.current && !ref.current.contains(event.target as Node))) {
        onClick();
      }
    }

    function onMouseDown(event: MouseEvent) {
      if (avoidClickNodeRefs.every((ref) => ref.current && !ref.current.contains(event.target as Node))) {
        document.addEventListener('mouseup', onMouseUp, useCapture);
      }
    }

    document.addEventListener('mousedown', onMouseDown, useCapture);

    return () => {
      document.removeEventListener('mousedown', onMouseDown, useCapture);
      document.removeEventListener('mouseup', onMouseUp, useCapture);
    };
  }, [disabled, onClick, avoidClickNodeRefs]);
}
