import {DataState} from '@joomcode/deprecated-utils/dataState';
import {useValueRef} from '@joomcode/deprecated-utils/react/useValueRef';
import {SearchCategoryTree as SearchCategoryTreeBase} from 'components/SearchCategoryTree';
import {CategoryPositionOnPage} from 'events/types/CategoryPositionOnPage';
import {makeCategoryIndex} from 'lib/category';
import {Category, RootCategory} from 'lib/category/types';
import {useEvent, useStore} from 'lib/effector';
import {reverseUrl} from 'lib/router';
import {$categories} from 'models/categories';
import {$categoriesTree, loadCategoriesTreeFx} from 'models/categoriesTree';
import {useRouter} from 'next/router';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {normalizeCategory, isSynteticCategory} from './utils';

type Props = {
  initialCategory?: Category | RootCategory;
  position: CategoryPositionOnPage;
};

export function SearchCategoryTree({initialCategory: category, position}: Props): React.ReactElement {
  const intl = useIntl();
  const router = useRouter();
  const {categories} = useStore($categories);
  const categoriesTree = useStore($categoriesTree);
  const loadCategoriesTree = useEvent(loadCategoriesTreeFx);

  const initialCategory = useMemo<Category>(() => {
    return category ? normalizeCategory(category, intl) : normalizeCategory({children: categories}, intl);
  }, []);

  const [currentCategory, setCurrentCategory] = useState<Category>(initialCategory);
  const currentCategoryRef = useValueRef(currentCategory);
  const rootCategoryIndex = useMemo(
    () => makeCategoryIndex(categoriesTree.data.children ?? categories ?? []),
    [categoriesTree.data, categories],
  );

  const handleClickCategory = useCallback(
    (item: Category) => {
      if (item.hasPublicChildren && item.id !== currentCategoryRef.current.id) {
        setCurrentCategory(rootCategoryIndex[item.id]);
      } else {
        router.push(reverseUrl.search({categoryId: item.id}));
      }
    },
    [router.push, rootCategoryIndex, setCurrentCategory],
  );

  const handleClickBack = useCallback(() => {
    const {parentId} = currentCategoryRef.current;
    if (parentId && parentId in rootCategoryIndex) {
      setCurrentCategory(rootCategoryIndex[parentId]);
    } else {
      setCurrentCategory(normalizeCategory({children: categories}, intl));
    }
  }, [intl, setCurrentCategory, categories, rootCategoryIndex]);

  useEffect(() => {
    if (!isSynteticCategory(currentCategory) && categoriesTree.dataState === DataState.IDLE) {
      if (currentCategory.hasPublicChildren && !currentCategory.children?.length) {
        loadCategoriesTree();
      }
    }
  }, [currentCategory]);

  useEffect(() => {
    if (currentCategory.id in rootCategoryIndex) {
      setCurrentCategory(rootCategoryIndex[currentCategory.id]);
    }
  }, [rootCategoryIndex]);

  const withCategory = currentCategory.id !== initialCategory.id;
  const isRootCategory = isSynteticCategory(currentCategory);

  return (
    <SearchCategoryTreeBase
      category={currentCategory}
      categoryState={categoriesTree.dataState}
      iconSize='small'
      isRoot={isRootCategory}
      onBack={handleClickBack}
      onClickCategory={handleClickCategory}
      position={position}
      withCategory={withCategory}
      withHeader={!isRootCategory}
    />
  );
}
