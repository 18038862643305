import cn from 'classnames';
import {Category} from 'lib/category/types';
import React from 'react';
import {IconSize} from '../types';
import styles from './index.module.scss';

export type Props = {
  category: Category;
  size?: IconSize;
};

export function Icon({category, size}: Props): React.ReactElement {
  return <div className={cn(styles.icon, size && styles[size])} data-id={category.id} />;
}
