import {assertNever} from '@joomcode/deprecated-utils/types';
import {getSearchSuggestionStoreId} from 'components/SearchBase/utils';
import {reverseUrl} from 'lib/router';
import {SearchSuggestion, SearchSuggestionKind} from 'lib/search/suggest';
import {SearchParams} from 'lib/search/types';

export function getUrlBuilder(): (props?: SearchParams | string) => string {
  return reverseUrl.search;
}

export function getUrlBySuggestion(suggestion: SearchSuggestion): string | undefined {
  const {kind, searchParams} = suggestion;
  const urlBuilder = getUrlBuilder();

  switch (kind) {
    case SearchSuggestionKind.STORE: {
      const storeId = getSearchSuggestionStoreId(suggestion);
      if (storeId) {
        return urlBuilder({storeId});
      }

      return undefined;
    }
    case SearchSuggestionKind.TEXT: {
      return urlBuilder(searchParams);
    }
    default:
      assertNever(kind);
      return undefined;
  }
}
