import {Category, isRootCategory, RootCategory} from 'lib/category/types';
import {IntlShape} from 'react-intl';
import {messages} from './messages';

export const ROOT_CATEGORY_ID = 'root' as const;
export const isSynteticCategory = (category: Category): boolean => {
  return category.id === ROOT_CATEGORY_ID;
};

export const normalizeCategory = (category: RootCategory | Category, intl: IntlShape): Category => {
  return isRootCategory(category)
    ? {
        children: category.children,
        id: ROOT_CATEGORY_ID,
        name: intl.formatMessage(messages.catalog),
      }
    : category;
};
