import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  hide: {
    defaultMessage: 'Hide all',
    description: 'Disclosure button: label of changing visibility items',
  },
  show: {
    defaultMessage: 'Show all',
    description: 'Disclosure button: label of changing visibility items',
  },
});
