import {normalizePhrase} from 'lib/search';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

export type HighlightedTextTestId = {
  mark: unknown;
};

type Props = TestIdProp<HighlightedTextTestId> & {
  children: string;
  value: string;
};

export function HighlightedText({children, value, testId}: Props): React.ReactElement {
  const normalizedValue = normalizePhrase(value.toLocaleLowerCase());
  const normalizedItem = normalizePhrase(children.toLocaleLowerCase());

  if (normalizedItem.startsWith(normalizedValue)) {
    const {length} = normalizedValue;
    const startOfItem = children.substr(0, length);
    const endOfItem = children.substr(length);

    return (
      <span data-test-id={testId}>
        <mark className={styles.mark} data-test-id={testId?.mark}>
          {startOfItem}
        </mark>
        {endOfItem}
      </span>
    );
  }

  return <span data-test-id={testId}>{children}</span>;
}
