import {Link} from 'components/Link';
import {useAnalytics} from 'lib/analytics';
import {useAppEnv} from 'lib/appEnv';
import React, {useCallback} from 'react';
import styles from './index.module.scss';

type Props = {
  href: string;
  icon?: React.ReactElement;
  label: React.ReactNode;
};

export function NavLink({href, label, icon}: Props): React.ReactElement {
  const appEnv = useAppEnv();
  const analytics = useAnalytics();
  const handleClick = useCallback(() => {
    analytics.sendEvent({
      payload: {
        pageUrl: window.location.href,
        source: appEnv.currentPageName,
        targetUrl: href,
      },
      type: 'navigationClick',
    });
  }, [analytics.sendEvent, appEnv.currentPageName, href]);

  return (
    <Link className={styles.link} href={href} onClick={handleClick} target='_blank'>
      <span className={styles.content}>
        {icon && <span className={styles.icon}>{icon}</span>}
        <span className={styles.label}>{label}</span>
      </span>
    </Link>
  );
}
