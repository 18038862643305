import {Category} from 'lib/category/types';
import React, {useState} from 'react';
import {CategoryColumns} from './CategoryColumns';
import {CategoryRootButton} from './CategoryRootButton';
import styles from './index.module.scss';
import {Color} from './types';

const DEFAULT_CATEGORY_CHILDREN: Category[] = [];

export type Props = {
  categories: Category[];
  color?: Color;
};

export function CategoriesCatalog({color = 'black', categories}: Props): React.ReactElement {
  const [activeCategory, setActiveCategory] = useState<Category | undefined>(categories[0]);

  let children = DEFAULT_CATEGORY_CHILDREN;
  if (activeCategory) {
    children = activeCategory.children ?? [];
  }

  return (
    <div className={styles.container}>
      <div className={styles.side}>
        {categories.map((category) => (
          <CategoryRootButton
            category={category}
            color={color}
            isActive={category.id === activeCategory?.id}
            key={category.id}
            onSelect={setActiveCategory}
          />
        ))}
      </div>
      <div className={styles.separator} />
      <div className={styles.main}>
        <div className={styles.title}>{activeCategory?.name}</div>
        <CategoryColumns categories={children} color={color} />
      </div>
    </div>
  );
}
